<template>
	<div class="icp">
			<img class="img" src="@/assets/images/icp.jpg" alt="">
	</div>
</template>

<script>

export default {
	title: 'icp',


	
	watch: {

	},

	methods: {
	}
};
</script>

<style lang="scss" scoped>
.img{
	width:700px;
}
</style>
